export interface LocationAvailabilities {
  availablePitchesNumber: number,
  totalPitchesNumber: number
  unavailablePitchesNumber: number,
}

export class APILocationService {

  private homeAPI = "http://api.campingcarpark.io/automaton";
  public RESOURCES = {
    GET_LOCATION: '/locations/{id}',
    GET_LOCATION_AVAILABILITIES: '/locations/{id}/availabilities',
  };

  public async getLocationDetails(id: number): Promise<any> {
    const url = this.homeAPI.concat(this.RESOURCES.GET_LOCATION.replace('{id}', id.toString()));
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        return data;
      });
  }

  public async getLocationAvailabilities(id: number): Promise<LocationAvailabilities> {
    const url = this.homeAPI.concat(this.RESOURCES.GET_LOCATION_AVAILABILITIES.replace('{id}', id.toString()));
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        return data;
      });
  }
}
