import { APILocationService, LocationAvailabilities } from "../services/locations-services.api";

const urlParams = window.location.search;
const locationId = urlParams.substring(urlParams.indexOf('=') + 1);
const REFRESH_INTERVAL_DELAY = 30_000;

async function getLocationDetail() {
  const service = new APILocationService();
  const details = await service.getLocationDetails(Number(locationId));
  displayName(details.name);
}

async function getLocationAvailabilities() {
  const service = new APILocationService();
  const availabilities = await service.getLocationAvailabilities(Number(locationId));
  displayAvailabilities(availabilities);
}

function displayName(name: string) {
  const nameContainer = document.getElementById('location');
  if(nameContainer !== null) {
    nameContainer.textContent = name;
  }
}

function displayAvailabilities(availabilities: LocationAvailabilities) {
  const counter = document.getElementById('availability-counter');
  if(counter !== null ) {
    counter.innerHTML = `<span class="bold">${availabilities.availablePitchesNumber}</span>/${availabilities.totalPitchesNumber}`;
  }
}


function init() {
  getLocationDetail();
  getLocationAvailabilities();

  setInterval( () => {
    getLocationAvailabilities();
  }, REFRESH_INTERVAL_DELAY);  
}

init();